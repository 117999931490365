import * as styles from "./image.module.css"

import React from "react"

const Image = ({ className, img, img2, caption, caption2 }) => (
  <div className={`text-center mb-5 ${className}`}>
    <div className="d-flex justify-content-around">
      <img src={img} className="img-fluid mb-4" alt={caption} />
      {img2 && <img src={img2} className="img-fluid mb-4 ml-3" alt={caption} />}
    </div>

    <div className="d-flex justify-content-around">
      {caption && <div className={styles.caption}>{caption}</div>}
      {caption2 && <div className={styles.caption}>{caption2}</div>}
    </div>
  </div>
)

export default Image
