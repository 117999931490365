import { FaEnvelope, FaLinkedinIn } from "react-icons/fa"

import Image from "../components/image/image"
import Layout from "../components/layout"
import React from "react"
import Section from "../components/section/section"
import Seo from "../components/seo"
import drawing1 from "../images/drawings/interest-1.jpg"
import drawing2 from "../images/drawings/interest-2.jpg"
import drawing3 from "../images/drawings/interest-3.jpg"
import drawing4 from "../images/drawings/interest-4.jpg"
import drawing5 from "../images/drawings/interest-5.jpg"
import qualificationsImg from "../images/experience.png"
import slavaPhoto from "../images/slava.png"

const About = () => (
  <Layout>
    <Seo title="About" />

    <Section background={true}>
      <div className="row">
        <div className="col-12 col-md-6">
          <Image img={slavaPhoto} />
        </div>
        <div className="col-12 col-md-6">
          <h3>Hi, I’m Slava Kovalska</h3>

          <p>
            An analytically curious-minded designer who enjoys deep diving into
            problem details and breaking complex concepts into smaller more
            manageable components.
          </p>
          <p>
            My accounting background allows me systematically explore multiple
            solutions that accommodate business needs with user expectations.
          </p>
          <p>
            Besides work, I enjoy reading, walking and taking care of my furry
            baby, 🐈 Ash, who loves when I chase him around our apartment.
          </p>
          <strong>
            <p>My Vision</p>
          </strong>
          <p>
            Craft solutions that ultimately translate into impactful user
            experiences and positive business outcomes.
          </p>
          <hr />

          <div className="d-flex ">
            <a
              href="https://www.linkedin.com/in/slava-kovalska/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="d-flex align-items-center mr-4">
                <FaLinkedinIn className="mr-2" />
                LinkedIn
              </span>
            </a>
            <a href="mailto:designwithslava@gmail.com">
              <span className="d-flex align-items-center">
                <FaEnvelope className="mr-2" />
                Email
              </span>
            </a>
          </div>
        </div>
      </div>
    </Section>

    <Section heading="Experience" className="d-none d-sm-block">
      <Image className="mt-5" img={qualificationsImg} />
    </Section>

    <Section
      className="experience"
      heading="Interests"
      subheading="I love discovering new places and enjoy experimenting with colours through acrylic painting."
    >
      <div className="row">
        <div className="col-12 col-md-4">
          <img src={drawing1} className="image" alt="Slava's interests" />
          <img src={drawing2} className="image" alt="Slava's interests" />
        </div>
        <div className="col-12 col-md-4">
          <img src={drawing3} className="image" alt="Slava's interests" />
        </div>
        <div className="col-12 col-md-4">
          <img src={drawing4} className="image" alt="Slava's interests" />
          <img src={drawing5} className="image" alt="Slava's interests" />
        </div>
      </div>
    </Section>
  </Layout>
)

export default About
