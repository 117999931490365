import * as styles from "./footer.module.css"

import { FaEnvelope, FaLinkedinIn } from "react-icons/fa"

import React from "react"

const Footer = () => (
  <footer className={styles.footer}>
    <div className="container">
      <div className={styles.text}>
        <h3>Got a project in mind?</h3>
        <h3>Let's make something awesome together.</h3>
      </div>

      <div className="d-flex mb-5">
        <a
          href="https://www.linkedin.com/in/slava-kovalska/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={styles.contact}>
            <FaLinkedinIn className={styles.icon} />
            LinkedIn
          </span>
        </a>
        <a href="mailto:designwithslava@gmail.com">
          <span className={styles.contact}>
            <FaEnvelope className={styles.icon} />
            Email
          </span>
        </a>
      </div>

      <div className={styles.copyright}>
        <p>&copy; Copyright Slava Kovalska </p>
      </div>
    </div>
  </footer>
)

export default Footer
