/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import "bootstrap/dist/css/bootstrap.min.css"
import "video-react/dist/video-react.css"
import "./layout.css"

import Footer from "./footer/footer"
import Header from "./header/header"
import PropTypes from "prop-types"
import React from "react"
import { Script } from "gatsby"

const Layout = ({ children }) => {
  return (
    <>
      <Header />

      {children}

      <Footer />
      <Script
        id="analytics"
        src="https://sdk.intent.upflowy.com/upflowy-intent.min.js?wid=UW-sm3CLyGSi4"
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
