import * as styles from "./section.module.css"

import React from "react"
import classNames from "classnames"

const Section = ({
  id,
  heading,
  subheading,
  children,
  background,
  className,
}) => (
  <section
    id={id}
    className={classNames(
      styles.section,
      { [styles.background]: background },
      className
    )}
  >
    <div className="container">
      {(heading || subheading) && (
        <div className="mb-4">
          {heading && <h2>{heading}</h2>}
          {subheading && <p>{subheading}</p>}
        </div>
      )}

      {children}
    </div>
  </section>
)

export default Section
